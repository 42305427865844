import React from "react";
import verfied from "../../resources/images/icon_review_verification_amazon.svg";
import avatar from "../../resources/images/icon_avatar_dark.svg";
import RichtextBlok from "./richtext-blok";
import amazon from "../../resources/images/amazon.png";
import Stars from "../commons/stars";

const ReviewInfo = ({ r, withSource, showFullText }) => {
  const { nome, profile_pic, titolo, rating, text, source, snippet } =
    r.content;
  const hasProfilePic = profile_pic.filename !== "";
  const profilePic = hasProfilePic ? profile_pic.filename : avatar;

  return (
    <>
      <div className="w-full flex items-end justify-between">
        <div className="flex items-center">
          <div className="overflow-hidden w-8 h-8 rounded-full mr-3">
            <img
              src={profilePic}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <p>{nome}</p>
          <img src={verfied} alt="recensione verificata" className="w-6" />
        </div>
        {withSource && source == "amazon" && (
          <img src={amazon} alt="verificata da amazon" className="w-24" />
        )}
      </div>
      <h3 className="font-bold mt-2">{titolo}</h3>
      <div className="mt-2">
        <Stars rating={rating} />
      </div>
      <div className="mt-5 text-sm">
        {showFullText || !snippet ? (
          <RichtextBlok data={text} pClassName="" />
        ) : (
          snippet
        )}
      </div>
    </>
  );
};

ReviewInfo.defaultProps = {
  withSource: false,
  showFullText: false,
};

export default ReviewInfo;
