import React, { useState } from "react";
import useWindowSize from "../use-window-size";
import { Link } from "gatsby";
import ReviewPopup from "./review-popup";
import ReviewInfo from "./review-info";
import Button from "../button";
import { responsiveGet } from "../utils";
import { useEffect } from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context";

const ReviewBlok = ({ reviews, withButton, withHeader, tit, sub, text }) => {
  const { isMobile, width } = useWindowSize();
  const allReviews = reviews;

  const perChunk = responsiveGet(width, { mob: 2, sm: 4, md: 4, def: 6 });
  const reviewChunks = allReviews.reduce((all, one, i) => {
    const ch = Math.floor(i / perChunk);
    all[ch] = [].concat(all[ch] || [], one);
    return all;
  }, []);
  const [activeChunk, setActiveChunk] = useState(0);

  useEffect(() => {
    setActiveChunk(0);
  }, [perChunk]);

  const { t } = useContext(LanguageContext);

  return (
    <div className="ci-cont">
      <div>
        {withHeader && (
          <>
            <p className="ci-sottotitolo text-center">{sub}</p>
            <h2 className="ci-h2 text-center mt-4 text-3xl sm:ci-text40">
              {tit}
            </h2>
            <p className="text-center mt-10 max-w-sm mx-auto leading-relaxed mb-16">
              {text}
            </p>
          </>
        )}
        <div className="overflow-hidden w-full">
          <div
            className="flex transition-all duration-300"
            style={{
              width: `${reviewChunks.length}00%`,
              marginLeft: `-${activeChunk * 100}%`,
            }}
          >
            {reviewChunks.map((rc, i) => (
              <div
                key={i}
                style={{ width: `${100 / reviewChunks.length}%` }}
                className="flex-shrink-0 flex flex-wrap justify-center gap-x-7 gap-y-16"
              >
                {rc.map((r) => (
                  <div
                    style={
                      isMobile
                        ? { width: "100%" }
                        : { width: "calc(33.33% - 20px)", minWidth: "390px" }
                    }
                    key={r.slug}
                  >
                    <Review r={r} />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        {reviewChunks.length > 1 && (
          <div className="mt-28 flex gap-1.5 justify-center">
            {reviewChunks.map((_, i) => (
              <button
                onClick={() => {
                  setActiveChunk(i);
                }}
                disabled={i === activeChunk}
                className="w-2.5 h-2.5 rounded-full border border-ci-green disabled:bg-ci-green"
              />
            ))}
          </div>
        )}
        {withButton && (
          <div className="text-center">
            <Link to="/" className="mt-10 inline-block">
              <Button type="green-outline">{t["read-reviews"]}</Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

ReviewBlok.defaultProps = {
  withButton: true,
  withHeader: true,
  reviews: [],
  tit: "Clienti felici",
  sub: "Recensioni",
  text: "Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia.",
};

const Review = ({ r }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useContext(LanguageContext);

  return (
    <>
      <div>
        <ReviewInfo r={r} />
        <a
          href={`#${r.slug}`}
          onClick={() => {
            setIsModalOpen(true);
          }}
          className="text-xs text-ci-blue mt-5"
        >
          {t["leggi-tutto"]}
        </a>
      </div>
      <ReviewPopup r={r} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </>
  );
};

export default ReviewBlok;

export { Review };
