import React, { useContext } from "react";
import useWindowSize from "../use-window-size";
import { responsiveGet } from "../utils";
import { Link } from "gatsby";
import { useState } from "react";
import Button from "../button";
import BlogArticleCard from "./blog-article-card";
import useBasePagesSEO from "../use-base-pages-seo";
import { LanguageContext } from "../../context/language-context";

const NewsList = ({ articles }) => {
  const { width } = useWindowSize();
  const needFilter = responsiveGet(width, {
    mob: false,
    sm: false,
    md: false,
    def: true,
  });
  const boxW = responsiveGet(width, { mob: 315, def: 480 });

  const { pageInfo } = useBasePagesSEO();
  const l = useContext(LanguageContext).locale;

  const [active, setActive] = useState(0);
  return (
    <section className="my-5 lg:ci-cont">
      <p className="ci-sottotitolo text-center">Blog</p>
      <h2 className="mt-3 ci-h2 text-3xl sm:ci-text40 text-center">
        News e Tutorial
      </h2>
      <p className="text-sm md:text-base mt-4 max-w-xs text-center mx-auto">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget
        lacinia.
      </p>
      <div className="pl-7 sm:pl-20 lg:pl-0 w-full overflow-hidden">
        <div
          onScroll={(e) => {
            const scrolled = e.target.scrollLeft;
            console.log(scrolled);
            const value = Math.min(
              Math.max(0, Math.round(scrolled / boxW)),
              articles.length - 1
            );
            console.log(value);
            setActive(value);
          }}
          className="flex items-start overflow-x-auto scroll-smooth pb-4 mt-10 snap-mandatory snap-x hide-bar gap-4 lg:gap-7 pr-7 lg:pr-0 lg:snap-none"
        >
          {articles
            .filter((_, i) => !needFilter || i < 2)
            .map((a) => (
              <div
                className="lg:flex-1 flex-shrink-0 snap-start"
                style={{
                  width: responsiveGet(width, {
                    mob: "315px",
                    sm: "480px",
                    md: "480px",
                    def: "40%",
                  }),
                }}
              >
                <BlogArticleCard a={a} key={a["_uid"]} />
              </div>
            ))}
        </div>
      </div>
      {!needFilter && (
        <div className="mt-5 flex gap-1.5 justify-center">
          {articles.map((_, i) => (
            <div
              className={`w-2 h-2 border border-ci-green rounded-full ${
                active === i ? "bg-ci-green" : ""
              }`}
              key={_["_uid"]}
            />
          ))}
        </div>
      )}
      <div className="flex justify-center mt-10">
        <Link to={pageInfo["Blog"][l].url}>
          <Button type="green-outline">
            {responsiveGet(width, {
              mob: "Leggi il nostro Blog",
              def: "Leggi un altro articolo dal nostro Blog",
            })}
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default NewsList;
