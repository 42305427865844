import { Link } from "gatsby";
import React from "react";
import Button from "../button";

// expect different feats basing on being mob, tab or desk
const ImageFeats = ({ feats }) => (
  <section className="my-10 flex sm:gap-4 lg:gap-3">
    {feats.map((f) => (
      <IFeat f={f} key={f["_uid"]} />
    ))}
  </section>
);

const IFeat = ({ f }) => {
  const { img, titolo, testo, cta_link, cta_text } = f.content;
  return (
    <div className="flex-1 grid shadow-md sm:shadow-none">
      <img
        src={img.filename}
        alt={img.alt ?? ""}
        className="w-full grid-all"
        style={{ aspectRatio: 375 / 300 }}
      />
      <div className="h-full bg-black bg-opacity-50 grid-all" />
      <div className="grid place-items-center text-white text-center grid-all">
        <div>
          <h3 className="ci-h4 sm:ci-h3">{titolo}</h3>
          <p className="text-sm sm:text-base w-3/4 mx-auto mt-4">{testo}</p>
          <div className="mt-8 flex justify-center">
            <Link to={cta_link}>
              <Button type="red">{cta_text}</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageFeats;
