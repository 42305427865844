import React from "react";
import useWindowSize from "../use-window-size";
import { responsiveGet } from "../utils";
import arrIc from "../../resources/images/icon_arrow_cta.svg";
import { Link } from "gatsby";

const BlogArticleCard = ({ a }) => {
  const { width } = useWindowSize();

  const { img, abstract, titolo, url } = a.content;
  const tags = (a.tag_list ?? [])
    .filter((t) => t.startsWith("c-"))
    .map((t) => t.slice(2, 3).toUpperCase() + t.slice(3));

  return (
    <article id={a.slug}>
      <img
        src={img.filename}
        alt={img.alt ?? ""}
        className="w-full object-cover"
        style={{
          aspectRatio: responsiveGet(width, {
            mob: "1.37",
            sm: "1.37",
            md: "1.37",
            def: "1.71",
          }),
        }}
      />
      <p className="text-ci-green font-bold mt-5">{tags.join(", ")} &nbsp;</p>
      <Link to={url}>
        <h3 className="mt-3 ci-h4 sm:ci-h3">{titolo ?? a.name}</h3>
      </Link>
      <p className="text-sm sm:text-base mt-3">{abstract}</p>
      <Link
        to={url}
        className="mt-3 font-bold text-sm sm:text-base flexc group"
      >
        <span>Leggi tutto</span>
        <img
          src={arrIc}
          alt=""
          className="transition-transform group-hover:translate-x-2"
        />
      </Link>
    </article>
  );
};

export default BlogArticleCard;
