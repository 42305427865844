import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import arrowL from "../../resources/images/icon_arrow_big_left.svg";
import arrowR from "../../resources/images/icon_arrow_big_right.svg";
import ReviewInfo from "./review-info";
import closeIc from "../../resources/images/close.svg";

const ReviewPopup = ({ r, isOpen, setIsOpen }) => {
  const [activeImg, setActiveImg] = useState(0);
  const imgsLen = (r?.content?.imgs ?? []).length;
  const hasImgs = imgsLen > 0;
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div className="min-h-screen grid place-content-center w-full">
          <Transition.Child
            as={Fragment}
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-70"
            entered="opacity-70"
            leave="duration-200"
            leaveFrom="opacity-70"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="inline-block px-4 max-w-screen-xl w-full transition-all transform"
              style={{ maxHeight: "475px" }}
            >
              <div className="flex gap-7 bg-white shadow-ci-card px-5 py-7 relative">
                <button
                  className="cursor-pointer absolute top-2 right-2"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <img src={closeIc} alt="Chiudi l'ispirazione" />
                </button>
                {hasImgs && (
                  <div className="w-5/12 flex-shrink-0">
                    <div className="flex items-center">
                      <button
                        className="w-6 flex-shrink-0"
                        disabled={activeImg === 0}
                        onClick={() => {
                          setActiveImg((old) => Math.max(0, old - 1));
                        }}
                      >
                        <img src={arrowL} alt="immagine precedente" />
                      </button>
                      <div className="flex-grow overflow-hidden">
                        <div
                          className="transition-all flex"
                          style={{
                            width: `${imgsLen}00%`,
                            marginLeft: `-${activeImg}00%`,
                          }}
                        >
                          {r.content.imgs.map((img) => (
                            <div key={img.filename}>
                              <img src={img.filename} alt={img.alt ?? ""} />
                            </div>
                          ))}
                        </div>
                      </div>
                      <button
                        className="w-6 flex-shrink-0"
                        disabled={activeImg === imgsLen - 1}
                        onClick={() => {
                          setActiveImg((old) => Math.min(imgsLen - 1, old + 1));
                        }}
                      >
                        <img src={arrowR} alt="immagine successiva" />
                      </button>
                    </div>
                    <div className="flex justify-center gap-1.5 mt-3.5">
                      {[...Array(imgsLen).keys()].map((i) => (
                        <div
                          key={i}
                          className={`w-2.5 h-2.5 rounded-full border-ci-gray border ${
                            i === activeImg ? "bg-ci-gray" : ""
                          }`}
                        />
                      ))}
                    </div>
                  </div>
                )}
                <div
                  className="pr-5 pt-5 overflow-y-auto"
                  style={{ maxHeight: "400px" }}
                >
                  <ReviewInfo r={r} withSource={true} showFullText={true} />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ReviewPopup;
