import React from "react";
import { useState } from "react";
import { useContext } from "react";
import Button from "../button";
import Overlay from "../commons/overlay";
import { EmailContext } from "../context/email-context";
import tl from "../../resources/images/overlays/tl-news.svg";
import br from "../../resources/images/overlays/br-news.svg";
import { responsiveGet } from "../utils";
import useWindowSize from "../use-window-size";
import { LanguageContext } from "../../context/language-context";

const Newsletter = () => {
  const { email, setEmail } = useContext(EmailContext);
  const [newsletterMail, setNewsletter] = useState(email ?? "");
  const { width } = useWindowSize();

  const { t } = useContext(LanguageContext);

  return (
    <section className="my-10 lg:my-20 sm:px-20 lg:px-8">
      <div className="relative bg-ci-beige-light lg:ci-cont sm:rounded-ci-newsletter shadow-lg overflow-hidden">
        <form
          className="px-8 pt-12 pb-10 sm:py-10 sm:px-16 lg:mx-auto"
          style={{ maxWidth: "908px" }}
        >
          <p className="ci-sottotitolo text-center">
            {t["newsletter-occhiello"]}
          </p>
          <h2 className="ci-h2 text-3xl lg:ci-text40 text-center sm:mt-4">
            {t["newsletter-tit"]}
          </h2>
          <div className="relative mt-8">
            <input
              className="ci-input sm:pr-72"
              placeholder={t["newsletter-text"]}
              value={newsletterMail}
              onChange={(e) => {
                setNewsletter(e.target.value);
              }}
            />

            <button className="mt-4 w-full sm:absolute sm:right-0 sm:top-px sm:mt-0 sm:w-auto">
              <Button type="red" className="px-6 py-4">
                <span className="font-bold uppercase p-0">
                  {t["newsletter-cta"]}
                </span>
              </Button>
            </button>
          </div>
          <label className="flex gap-3 items-start text-xs mt-6 w-10/12">
            <input type="checkbox" name="termini" />
            {t["newsletter-terms"]}
          </label>
        </form>
        <Overlay>
          <img
            src={tl}
            alt=""
            className="absolute"
            style={responsiveGet(width, {
              mob: { width: "388px", left: "-208px", top: "-30px" },
              sm: { width: "388px", left: "-156px", top: "-34px" },
              md: { width: "388px", left: "-156px", top: "-34px" },
              def: { width: "550px", left: "-138px", top: "-37px" },
            })}
          />
          <img
            src={br}
            alt=""
            className="absolute"
            style={responsiveGet(width, {
              mob: { width: "324px", right: "-153px", bottom: "-8px" },
              sm: { width: "550px", right: "-341px", bottom: "-21px" },
              md: { width: "550px", right: "-341px", bottom: "-21px" },
              def: { width: "550px", right: "-190px", bottom: "-26px" },
            })}
          />
        </Overlay>
      </div>
    </section>
  );
};

export default Newsletter;
