import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import f1 from "../../resources/images/f1.json";
import f2 from "../../resources/images/f2.json";
import f3 from "../../resources/images/f3.json";
import f4 from "../../resources/images/f4.json";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context";

const FeaturesAnim = () => {
  const { t } = useContext(LanguageContext);
  const features = [
    {
      anim: f1,
      tit: t["f1-tit"],
      text: t["f1-text"],
    },
    {
      anim: f2,
      tit: t["f2-tit"],
      text: t["f2-text"],
    },
    {
      anim: f3,
      tit: t["f3-tit"],
      text: t["f3-text"],
    },
    {
      anim: f4,
      tit: t["f4-tit"],
      text: t["f4-text"],
    },
  ];
  return (
    <section className="my-6 px-7 lg:ci-cont lg:my-14">
      <div className="flexc gap-4 sm:gap-6 flex-wrap lg:flex-nowrap">
        {features.map((f) => (
          <Feat f={f} />
        ))}
      </div>
    </section>
  );
};

const Feat = ({ f }) => {
  const animRef = useRef(null);
  const elementRef = useRef(null);

  const { inViewport } = useInViewport(
    elementRef,
    {},
    { disconnectOnLeave: false }
  );

  useEffect(() => {
    console.log({ inViewport, animRef, s: "ANIME " + f.tit });
    inViewport && animRef.current && animRef.current.play();
  }, [inViewport]);

  return (
    <div
      className="flexc flex-col text-center flex-grow w-1/3 lg:w-auto"
      ref={elementRef}
    >
      <div className="w-14 sm:w-16">
        <Player
          autoplay={false}
          loop={false}
          controls={false}
          src={f.anim}
          keepLastFrame={true}
          ref={animRef}
        />
      </div>
      <h3 className="font-bold mt-4 text-sm sm:text-base">{f.tit}</h3>
      <p className="mt-2 text-xs sm:text-sm">{f.text}</p>
    </div>
  );
};

export default FeaturesAnim;
