import React from "react";
import useShopifyProducts from "../use-shopify-products";
import RichtextBlok from "./richtext-blok";
import cartW from "../../resources/images/icon-cart-w.svg";
import { useContext } from "react";
import { StoreContext } from "../../context/store-context";
import { Link } from "gatsby";
import Button from "../button";
import useProductsDescReview from "../use-products-desc-preview";
import { useState } from "react";
import { Review } from "./review-blok";
import { LanguageContext } from "../../context/language-context";
import useBasePagesSEO from "../use-base-pages-seo";
import { getTranslatedIfAvail } from "../utils";

const ProdottiBlokHome = ({ blok, q, reviews }) => {
  const { occhiello, titolo, testo, prodotto_block } = blok;
  let matchingProducts = prodotto_block.filter((p) => p.codice === q);
  const prodsInfo =
    matchingProducts.length > 0 ? matchingProducts[0] : prodotto_block[0];
  const allProds = useShopifyProducts();
  const allDescs = useProductsDescReview();
  const prods = [
    allProds[(prodsInfo?.sku1 ?? "").toUpperCase()],
    allProds[(prodsInfo?.sku2 ?? "").toUpperCase()],
  ];
  const descs = [
    allDescs[(prodsInfo?.sku1 ?? "").toUpperCase()] || prods[0].description,
    allDescs[(prodsInfo?.sku2 ?? "").toUpperCase()] || prods[1].description,
  ];

  const { addVariantToCart, loading } = useContext(StoreContext);

  const addToCart = (id) => () => {
    if (!loading) addVariantToCart(id, 1);
  };

  const [review] = useState(
    reviews[
      reviews.length === 1 ? 0 : Math.floor(Math.random() * reviews.length)
    ]
  );

  const { t, locale } = useContext(LanguageContext);
  const l = locale;
  const { pageInfo } = useBasePagesSEO();

  // ENG PRODS STORE
  const { translatedProds } = useContext(StoreContext);
  const getT = (sku) =>
    translatedProds[l] && translatedProds[l][sku.toLowerCase()]?.title;

  return (
    <section className="my-14">
      <div className="ci-cont lg:flex gap-7">
        <div className="px-7 sm:px-20 lg:px-0 lg:mt-10 lg:w-1/3 lg:flex-shrink-0">
          <p className="ci-sottotitolo">{occhiello}</p>
          <h2 className="ci-h2 text-3xl sm:ci-text40 mb-4 mt-2">{titolo}</h2>
          <RichtextBlok pClassName="" data={testo} />
          {reviews.length > 0 && (
            <div className="hidden lg:block mt-10">
              <Review r={review} />
            </div>
          )}
          <div className="lg:flex hidden mt-10">
            <Link to={pageInfo["Kit"][l].url}>
              <Button type="green-outline">{t["vedi-tutti-kit"]}</Button>
            </Link>
          </div>
        </div>
        <div className="overflow-hidden pl-7 mt-9 sm:px-20 sm:mt-16 lg:pl-0 lg:mt-0">
          <div className="flex items-start gap-5 overflow-x-auto scroll-smooth pb-4 pr-7 snap-mandatory snap-x hide-bar sm:snap-none sm:gap-6 sm:pb-0 sm:pr-0 lg:gap-7">
            {prods.map(
              (p, i) =>
                p && (
                  <div className="snap-start flex-shrink-0 w-72 sm:w-auto sm:flex-1">
                    <p
                      className={
                        "h-10 text-white text-center font-bold flex px-2 items-center justify-center bg-" +
                        (i === 0 ? "ci-" + prodsInfo.colore_chips : "white")
                      }
                    >
                      {i === 0 && prodsInfo.testo_chips}
                    </p>
                    <div className="relative group hover:rounded-b-ci-hov overflow-hidden transition-all">
                      <img src={p.images[1].src} alt={p.images[1]?.altText} />
                      <img
                        src={p.images[0].src}
                        alt={p.images[0]?.altText}
                        className="absolute w-full h-full top-0 left-0 object-cover opacity-100 lg:group-hover:opacity-0"
                      />
                      <button
                        className="grid place-items-center absolute bottom-2.5 left-2.5 rounded-full bg-ci-red lg:hidden disabled:opacity-60"
                        style={{ width: "46px", height: "46px" }}
                        onClick={addToCart(p.variants[0].storefrontId)}
                        disabled={loading}
                      >
                        <img
                          src={cartW}
                          alt="Aggiungi al carrello"
                          className="w-6"
                        />
                      </button>
                      <button
                        className="absolute bottom-0 left-0 hidden lg:block w-full opacity-0 group-hover:opacity-100 transition-all disabled:group-hover:opacity-60"
                        onClick={addToCart(p.variants[0].storefrontId)}
                        disabled={loading}
                      >
                        <Button type="red">
                          <span className="uppercase">
                            {t["aggiungi-carrello"]}
                          </span>
                        </Button>
                      </button>
                    </div>
                    <div className="mt-5">
                      <h3 className="ci-h3 text-xl lg:text-2xl">
                        {getTranslatedIfAvail(p.title, getT(p.handle), true)}
                      </h3>
                      <p className="mt-0.5 font-bold text-sm">
                        {getTranslatedIfAvail(p.title, getT(p.handle), false)}
                      </p>
                      <p className="mt-2 text-sm sm:text-base">{descs[i]}</p>
                      <div className="mt-4 flex gap-5 items-baseline">
                        {p.variants[0].compareAtPrice === null ? (
                          <p className="font-bold text-2xl">
                            €{p.variants[0].price}
                          </p>
                        ) : (
                          <>
                            <p className="font-bold line-through">
                              €{p.variants[0].compareAtPrice}
                            </p>
                            <p className="font-bold text-ci-red text-2xl">
                              €{p.variants[0].price}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
        <div className="flex justify-center lg:hidden mt-10">
          <Link to={pageInfo["Kit"][l].url}>
            <Button type="green-outline">{t["vedi-tutti-kit"]}</Button>
          </Link>
        </div>
        {reviews.length > 0 && (
          <div className="px-7 sm:px-20 lg:hidden mt-10">
            <Review r={review} />
          </div>
        )}
      </div>
    </section>
  );
};

export default ProdottiBlokHome;
