import { Link } from "gatsby";
import React, { useMemo } from "react";
import { useState } from "react";
import FeaturesAnim from "../commons/features-anim";
import ImageFeats from "../commons/footer-feats";
import NewsList from "../commons/news-list";
import Overlay from "../commons/overlay";
import Newsletter from "../home/newsletter";
import Layout from "../layout/layout";
import Faq from "../storyblok/faq";
import { getBlokComponent } from "../storyblok/get-blok";
import HeroGalleryBlok from "../storyblok/hero-gallery-blok";
import ReviewBlok from "../storyblok/review-blok";
import useWindowSize from "../use-window-size";
import { getRectParams, parseStory, responsiveGet } from "../utils";
import arrIc from "../../resources/images/icon_arrow_cta.svg";
import tlGreen2 from "../../resources/images/overlays/tl-green2.svg";
import faqAnim from "../../resources/images/anim-faq.json";
import { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import { Player } from "@lottiefiles/react-lottie-player";
import { useEffect } from "react";
import ProdottiBlokHome from "../storyblok/prodotti-blok-home";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context";
import useBasePagesSEO from "../use-base-pages-seo";

const IndexPage = ({ data, location }) => {
  const story = parseStory(data.storyblokEntry);
  const prodReviews = data.prodReviews.nodes.map(parseStory);
  const faqs = useMemo(() => data.faqs.nodes.map(parseStory), [data.faqs]);
  const reviews = data.reviews.nodes.map(parseStory);
  const articles = data.blog.nodes.map(parseStory);
  const imgFeat = data.imgFeat.nodes.map(parseStory);
  const imgFeatTab = data.imgFeatTab.nodes.map(parseStory);
  const imgFeatMob = data.imgFeatMob.nodes.map(parseStory);

  const { t, locale } = useContext(LanguageContext);
  const l = locale;

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let initIdx = 0;
  if ("h" in params) {
    switch (params.h) {
      case "family":
        initIdx = 1;
        break;
      case "baby":
        initIdx = 2;
        break;
    }
  }

  const [te, setTe] = useState(false);
  const { width } = useWindowSize();

  const imgFeats = responsiveGet(width, {
    mob: imgFeatMob,
    sm: imgFeatTab,
    md: imgFeatTab,
    def: imgFeat,
  });

  return (
    <Layout>
      <HeroGalleryBlok data={story.content.hero_gallery} initialIdx={initIdx} />
      <ProdottiBlokHome
        blok={story.content.prodotti[0]}
        q={params?.h ?? ""}
        reviews={prodReviews}
      />
      <div id={t["our-story-id"]}> </div>
      {story.content.content.slice(0, 3).map((c) => getBlokComponent(c))}
      <HomeFaqBlok faqs={faqs} />
      <section className="bg-ci-beige-light relative mt-28 py-40 z-10">
        <ReviewBlok
          reviews={reviews}
          tit={t["rev-home-tit"]}
          sub={t["rev-home-occhiello"]}
          text={t["rev-home-text"]}
          withButton={false}
        />
        <Overlay waveTop={true} waveBot={true}>
          <img
            src={tlGreen2}
            alt=""
            className="absolute"
            style={responsiveGet(width, {
              mob: { width: "120px", top: "-100px", left: "0px" },
              sm: { width: "242px", top: "-160px", left: "-65px" },
              md: { width: "242px", top: "-160px", left: "-65px" },
              xl: { width: "322px", top: "-110px", left: "-180px" },
              def: { width: "322px", top: "-50px", left: "-150px" },
            })}
          />
        </Overlay>
        <Overlay overflowHidden={false}>
          <DeskFaqAnim />
        </Overlay>
      </section>
      {story.content.content.slice(3).map((c) => getBlokComponent(c))}
      <ImageFeats feats={imgFeats} />
      <div id="blog"></div>
      <NewsList articles={articles} />
      <Newsletter />
      <FeaturesAnim />
      {/* <div className="h-screen bg-ci-beige-50 flex items-center justify-center">
        <button
          onClick={() => {
            setTe(true);
            setTimeout(() => {
              setTe(false);
            }, 20000);
          }}
        >
          <Button>
            <Loading isLoading={te}>Prova</Loading>
          </Button>
        </button>
      </div> */}
    </Layout>
  );
};

const HomeFaqBlok = ({ faqs }) => {
  const { t, locale } = useContext(LanguageContext);
  const l = locale;
  const { pageInfo } = useBasePagesSEO();
  return (
    <div className="lg:ci-cont sm:max-w-xl mx-auto px-8 flex justify-between gap-6 mt-32 flex-wrap lg:flex-nowrap">
      <div>
        <p className="ci-sottotitolo">{t["home-faq-occhiello"]}</p>
        <h2 className="ci-h2 text-3xl md:ci-text-40 mt-4">
          {t["home-faq-tit"]}
        </h2>
        <p className="mt-4 sm:w-1/2 lg:w-auto">{t["home-faq-text"]}</p>
        <Link
          to={pageInfo["Assistenza Clienti"][l].url}
          className="mt-4 flex items-center group"
        >
          <span className="font-bold">{t["home-faq-elenco"]}</span>
          <img
            src={arrIc}
            alt=""
            className="transform transition-transform group-hover:translate-x-2"
          />
        </Link>
      </div>
      <div className="flex-shrink-0 w-full lg:w-7/12 border-ci-gray-light border-t relative">
        {faqs.map((f) => (
          <Faq key={f.name} faq={f} />
        ))}
        <TabFaqAnim />
        <h4 className="font-bold text-lg mt-12">{t["vuoi-parlarci"]}</h4>
        <div className="flex mt-8 gap-y-4 gap-x-8 flex-wrap">
          <a
            href="mailto:ciao@calchitaliani.com"
            className="flex items-center group"
          >
            <span className="font-bold">{t["scrivi-mail"]}</span>
            <img
              src={arrIc}
              alt=""
              className="transform transition-transform group-hover:translate-x-2"
            />
          </a>
          <a
            href="https://m.me/calchitaliani"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center group"
          >
            <span className="font-bold">{t.chatta}</span>
            <img
              src={arrIc}
              alt=""
              className="transform transition-transform group-hover:translate-x-2"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const TabFaqAnim = () => {
  const animRef = useRef(null);
  const elementRef = useRef(null);

  const { inViewport } = useInViewport(
    elementRef,
    {},
    { disconnectOnLeave: false }
  );

  useEffect(() => {
    inViewport &&
      animRef.current &&
      setTimeout(() => {
        animRef.current.play();
      }, 1000);
  }, [inViewport]);

  return (
    <div
      ref={elementRef}
      className="absolute bottom-full overflow-hidden hidden sm:block lg:hidden"
      style={{ width: "240px", right: "46px" }}
    >
      <Player
        autoplay={false}
        loop={false}
        controls={false}
        src={faqAnim}
        keepLastFrame={true}
        ref={animRef}
        className="w-full relative"
        style={{ bottom: "-120px" }}
      />
    </div>
  );
};

const DeskFaqAnim = () => {
  const animRef = useRef(null);
  const elementRef = useRef(null);

  const { inViewport } = useInViewport(
    elementRef,
    {},
    { disconnectOnLeave: false }
  );

  useEffect(() => {
    inViewport &&
      animRef.current &&
      setTimeout(() => {
        animRef.current.play();
      }, 1000);
  }, [inViewport]);

  const left = getRectParams(-20, 3);
  const top = getRectParams(531, 484);
  const { width } = useWindowSize();

  return (
    <div
      ref={elementRef}
      className="absolute hidden lg:block"
      style={{
        width: "605px",
        left: `${left[0] * width + left[1]}%`,
        top: `-${top[0] * width + top[1]}px`,
      }}
    >
      <Player
        autoplay={false}
        loop={false}
        controls={false}
        src={faqAnim}
        keepLastFrame={true}
        ref={animRef}
        className="w-full"
      />
    </div>
  );
};

export default IndexPage;
