import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import RichtextBlok from "./richtext-blok";
import arrD from "../../resources/images/icon_arrow_down.svg";

const Faq = ({ faq }) => {
  return (
    <Disclosure
      id={faq.slug}
      as="div"
      className="border-ci-gray-light border-b"
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="py-4 w-full flex gap-4 items-center justify-between">
            <h4 className="ci-h4 text-left">{faq.content.question}</h4>
            <img
              src={arrD}
              className={`transition-transform transform duration-300 ${
                open ? "rotate-180" : ""
              }`}
            />
          </Disclosure.Button>
          <Transition
            enter="transition origin-top transform duration-300 ease-out"
            enterFrom="transform scale-y-0"
            enterTo="transform scale-y- 100"
            leave="transition origin-top transform duration-200 ease-in"
            leaveFrom=" scale-y- 100"
            leaveTo="scale-y-0"
          >
            <Disclosure.Panel className="pb-6" unmount={false}>
              <RichtextBlok data={faq.content.answer} />
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default Faq;
