import { Transition } from "@headlessui/react";
import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import hgOverlay from "../../resources/images/colore-sx-hero-home.png";
import hgBottom from "../../resources/images/onda-hero-slider.svg";
import RichtextBlok from "./richtext-blok";
import heroBg1 from "../../resources/images/hero-bg-1.svg";
import heroBg2 from "../../resources/images/hero-bg-2.svg";
import heroBg3 from "../../resources/images/hero-bg-3.svg";
import arrL from "../../resources/images/icon_arrow_big_left.svg";
import arrR from "../../resources/images/icon_arrow_big_right.svg";
import useWindowSize from "../use-window-size";
import { getRectParams } from "../utils";

import togSmall from "../../resources/images/hero/image_hero_together-1024@1x.png";
import togMed from "../../resources/images/hero/image_hero_together-1440@1x.png";
import togLarge from "../../resources/images/hero/image_hero_together-1920@1x.png";
import togXL from "../../resources/images/hero/image_hero_together-1920@2x.png";
import famSmall from "../../resources/images/hero/image_hero_family-1024@1x.png";
import famMed from "../../resources/images/hero/image_hero_family-1440@1x.png";
import famLarge from "../../resources/images/hero/image_hero_family-1920@1x.png";
import famXL from "../../resources/images/hero/image_hero_family-1920@2x.png";
import babySmall from "../../resources/images/hero/image_hero_baby-1024@1x.png";
import babyMed from "../../resources/images/hero/image_hero_baby-1440@1x.png";
import babyLarge from "../../resources/images/hero/image_hero_baby-1920@1x.png";
import babyXL from "../../resources/images/hero/image_hero_baby-1920@2x.png";
import { Player } from "@lottiefiles/react-lottie-player";
import animationBaby from "../../resources/images/hero/animation-baby.json";
import animationTogether from "../../resources/images/hero/animation-together.json";
import animationFamily from "../../resources/images/hero/animation-family.json";

import togMob from "../../resources/images/hero/mob/image_hero_together-375@1x.png";
import famMob from "../../resources/images/hero/mob/image_hero_family-375@1x.png";
import babyMob from "../../resources/images/hero/mob/image_hero_baby-375@1x.png";
import petsMob from "../../resources/images/hero/mob/image_hero_pets-375@1x.png";
import kisssMob from "../../resources/images/hero/mob/image_hero_kiss-375@1x.png";

import togTab from "../../resources/images/hero/tab/image_hero_together-768@1x.png";
import famTab from "../../resources/images/hero/tab/image_hero_family-768@1x.png";
import babyTab from "../../resources/images/hero/tab/image_hero_baby-768@1x.png";
import petsTab from "../../resources/images/hero/tab/image_hero_pets-768@1x.png";
import kisssTab from "../../resources/images/hero/tab/image_hero_kiss-768@1x.png";

import Button from "../button";
import Overlay from "../commons/overlay";

const numHeroBlocks = 3;

const galleryAlts = [
  "an happy couple",
  "an happy family",
  "a couple with an happy bebe",
];
const [bp1, bp2, bp3] = [1080, 1440, 1921];
const heightAdjustment = 0;
const galleryHeights = [
  // width vw * height / width
  heightAdjustment + (49 * 792) / 502,
  heightAdjustment + (55 * 787) / 811,
  heightAdjustment + (55 * 792) / 1058,
];

const HeroGalleryBlok = ({ data, initialIdx }) => {
  const [active, setActive] = useState(initialIdx);
  const [floatingMargin, setFloatingMargin] = useState(16);
  const [textHeight, setTextHeight] = useState(600);

  const { width } = useWindowSize();

  // BG images mobile
  const heroMob = [togMob, famMob, babyMob];

  // BG images tablet
  const heroTab = [togTab, famTab, babyTab];

  // calchi tablet style
  const calchiStyleTab = [
    { width: "40%", bottom: "50px", right: "15%" },
    { width: "40%", bottom: "40px", right: "15%" },
    { width: "60%", bottom: "0px", right: "15%" },
  ];

  // BG images
  let galHeight = galleryHeights[1];
  let heroImgs = [togMed, famMed, babyMed];
  if (width < bp1) {
    galHeight = galleryHeights[0];
    heroImgs = [togSmall, famSmall, babySmall];
  } else if (width >= bp2 && width < bp3) {
    galHeight = galleryHeights[2];
    heroImgs = [togLarge, famLarge, babyLarge];
  } else if (width >= bp3) {
    galHeight = galleryHeights[2];
    heroImgs = [togXL, famXL, babyXL];
  }

  // Lottie animation
  const lottieAnimations = [animationTogether, animationFamily, animationBaby];

  // illustrazioni specific vars
  const illMaxWidth = 744;
  const illWidthParams = getRectParams(524, 744, 1080, 1440);
  const illWidth = illWidthParams[0] * width + illWidthParams[1];
  const illHeight = Math.min(illMaxWidth, illWidth) / 3.06;
  const illLefts = [
    "60%",
    width > bp1 ? "50%" : "65%",
    width > bp1 ? "70%" : "66%",
  ];
  const illTops = [illHeight * 0.52, illHeight * 0.64, illHeight * 0.4];

  // calchi specific vars
  const calMaxWidth = 580;
  const calWidthParams = getRectParams(452, 580, 1080, 1920);
  const calWidth = calWidthParams[0] * width + calWidthParams[1];
  const calLefts = width > bp1 ? ["17%", "17%", "8%"] : ["4%", "4%", "-10%"];
  const calTops = width > bp1 ? ["48%", "48%", "50%"] : ["57%", "57%", "55%"];

  // overlay specific variables
  const breakpoint = 1440,
    pinkBottom = getRectParams(-105, 0),
    pinkLeft = getRectParams(-177, -108),
    blueTop = getRectParams(-285, -265),
    blueLeft = getRectParams(-80, -30);

  const lottieRefs = [useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const updDimensions = () => {
      setFloatingMargin(
        (document.querySelector("#header-bar")?.offsetLeft ?? 0) + 16
      );
      setTextHeight(
        document.querySelector(".gall-text-block")?.offsetHeight ?? 600
      );
    };

    setTimeout(updDimensions, 200);

    window.addEventListener("resize", updDimensions);

    return () => {
      window.removeEventListener("resize", updDimensions);
    };
  }, []);

  return (
    <>
      {/* MOBILE */}
      <div className="overflow-hidden sm:hidden">
        <div className="flex items-start overflow-x-auto scroll-smooth pb-4 snap-mandatory snap-x hide-bar">
          {[...Array(numHeroBlocks).keys()].map((idx) => (
            <div className="snap-start bg-ci-beige-light flex-shrink-0 w-screen relative">
              <div className="relative overflow-hidden">
                <div className="relative overflow-hidden">
                  <div
                    className="absolute w-full h-px"
                    style={{ top: "-68px", left: 0 }}
                    id={`hero-block-${idx}`}
                  />
                  <img src={heroMob[idx]} alt="" className="w-full" />
                  {idx > 0 && (
                    <a
                      href={`#hero-block-${idx - 1}`}
                      className="absolute left-4 w-8 top-1/2 -mt-4"
                    >
                      <img src={arrL} alt="prodotto precedente" />
                    </a>
                  )}
                  {idx + 1 < numHeroBlocks && (
                    <a
                      href={`#hero-block-${idx + 1}`}
                      className="absolute right-4 w-8 top-1/2 -mt-4"
                    >
                      <img src={arrR} alt="prodotto successivo" />
                    </a>
                  )}
                </div>
                <div className="w-11/12 -ml-16">
                  <Player
                    autoplay={true}
                    loop={false}
                    controls={false}
                    src={lottieAnimations[idx]}
                    ref={lottieRefs[idx]}
                    keepLastFrame={true}
                  />
                </div>
                <img
                  src={data[idx].calco.filename}
                  alt={data[idx].calco.alt}
                  className="absolute w-7/12 bottom-1 right-0"
                />
              </div>
              <div className="pt-5 px-7 pb-14">
                <h1 className="ci-h2 text-3xl">{data[idx].titolo}</h1>
                <RichtextBlok
                  data={data[idx].text}
                  pClassName="text-sm mt-3"
                  liClassName="mt-0"
                />
                <Link to={data[idx].cta_link} className="block mt-6">
                  <Button
                    type="red"
                    className="py-3.5 text-sm text-center font-bold px-5"
                  >
                    {data[idx].cta_text}
                  </Button>
                </Link>
              </div>
              <Overlay waveBot={true} />
            </div>
          ))}
        </div>
      </div>

      {/* TABLET */}
      <div className="hidden sm:block lg:hidden mb-10">
        <div className="flex items-start overflow-x-auto scroll-smooth pb-4 snap-mandatory snap-x hide-bar">
          {[...Array(numHeroBlocks).keys()].map((idx) => (
            <div className="snap-start bg-ci-beige-light flex-shrink-0 w-screen relative pb-28">
              <div className="flex relative overflow-hidden pb-4">
                <div
                  className="absolute w-full h-px"
                  style={{ top: "-68px", left: 0 }}
                  id={`hero-block-tab-${idx}`}
                />
                <div className="pt-20 pl-20 pr-7 pb-10">
                  <h1 className="ci-h2">{data[idx].titolo}</h1>
                  <RichtextBlok
                    data={data[idx].text}
                    pClassName="text-sm mt-3"
                    liClassName="mt-0"
                  />
                  <Link to={data[idx].cta_link} className="block mt-6">
                    <Button
                      type="red"
                      className="py-3.5 text-sm text-center font-bold px-5"
                    >
                      {data[idx].cta_text}
                    </Button>
                  </Link>
                  <div className="flex mt-10 gap-1">
                    {idx > 0 && (
                      <a href={`#hero-block-tab-${idx - 1}`}>
                        <img src={arrL} alt="prodotto precedente" width={46} />
                      </a>
                    )}
                    {idx + 1 < numHeroBlocks && (
                      <a href={`#hero-block-tab-${idx + 1}`}>
                        <img src={arrR} alt="prodotto successivo" width={46} />
                      </a>
                    )}
                  </div>
                  <div
                    className="-mt-2"
                    style={{ width: "150%", marginLeft: "-80px" }}
                  >
                    <Player
                      autoplay={true}
                      loop={false}
                      controls={false}
                      src={lottieAnimations[idx]}
                      ref={lottieRefs[idx]}
                      keepLastFrame={true}
                    />
                  </div>
                </div>
                <div style={{ width: "312px" }} className="flex-shrink-0">
                  <img src={heroTab[idx]} alt="" className="w-full" />
                </div>
              </div>

              <img
                src={data[idx].calco.filename}
                alt={data[idx].calco.alt}
                className="absolute z-20"
                style={calchiStyleTab[idx]}
              />
              <Overlay waveBot={true} />
            </div>
          ))}
        </div>
      </div>

      {/* DESKTOP */}
      <div className="relative bg-ci-beige-light mb-20 items-stretch hidden lg:flex">
        {/* Rich Text Blok */}
        <div
          className="relative z-10 mt-32 2xl:my-16 mb-10 self-center"
          style={
            width < bp1
              ? { flex: "0 0 51%", minHeight: `${textHeight}px` }
              : { flex: "0 0 45%", minHeight: `${textHeight}px` }
          }
        >
          {data.map((he, idx) => (
            <Transition
              show={active === idx}
              enter="transition transform duration-500 delay-500"
              enterFrom="opacity-0 -translate-x-40"
              enterTo="opacity-100 translate-x-0"
              leave="transform transition duration-500"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-40"
              className="absolute top-0 left-0 w-full gall-text-block"
              afterEnter={() => {
                lottieRefs[idx].current.play();
              }}
            >
              <div
                style={{
                  paddingLeft:
                    width < breakpoint ? "104px" : `${floatingMargin}px`,
                }}
              >
                <h1 className="ci-h1 text-5xl leading-none lg:text-6xl">
                  {he.titolo}
                </h1>
                <RichtextBlok data={he.text} />
                <div className="relative mt-8 inline-block">
                  <Link to={he.cta_link} className="relative z-20">
                    <Button type="red">{he.cta_text}</Button>
                  </Link>
                  <div
                    className="absolute illustrazione-hero"
                    style={{
                      width: `${illWidth}px`,
                      maxWidth: `${illMaxWidth}px`,
                      left: illLefts[idx],
                      top: `-${illTops[idx]}px`,
                      zIndex: "-15",
                    }}
                  >
                    <Player
                      autoplay={false}
                      loop={false}
                      controls={false}
                      className="w-full"
                      src={lottieAnimations[idx]}
                      ref={lottieRefs[idx]}
                      keepLastFrame={true}
                    />
                  </div>
                </div>
                <div className="mt-10 mb-20 2xl:mb-10">
                  <button
                    className="transition-transform transform hover:-translate-x-2"
                    onClick={() => {
                      setActive((s) => (--s + data.length) % data.length);
                    }}
                  >
                    <img src={arrL} alt="immagine precedente" />
                  </button>
                  <button
                    className="transition-transform transform hover:translate-x-2"
                    onClick={() => {
                      setActive((s) => ++s % data.length);
                    }}
                  >
                    <img src={arrR} alt="immagine successiva" />
                  </button>
                </div>
              </div>
            </Transition>
          ))}
        </div>

        {/* BG images */}
        <div
          className="relative z-20 flex-grow flex-shrink-0"
          style={{ minHeight: `${galHeight}vw` }}
        >
          {heroImgs.map((img, idx) => (
            <Transition
              unmount={false}
              show={active === idx}
              enter="transform transition duration-500 delay-500"
              enterFrom="opacity-0 translate-x-40"
              enterTo="opacity-100 translate-x-0"
              leave="transition transform duration-500"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-40"
              className="absolute z-20 top-0 left-0 w-full h-full"
            >
              <img
                src={img}
                alt={galleryAlts[idx]}
                className="w-full h-full object-cover object-left-bottom"
              />
              <img
                src={data[idx].calco.filename}
                alt={data[idx].calco.alt}
                className="absolute"
                style={{
                  maxWidth: `${calMaxWidth}px`,
                  width: `${calWidth}px`,
                  left: calLefts[idx],
                  top: calTops[idx],
                  zIndex: "35",
                }}
              />
            </Transition>
          ))}
        </div>

        {/* Overlay */}
        <div className="absolute w-full h-full left-0 top-0 overflow-hidden">
          <img
            src={heroBg2}
            alt=""
            className="absolute"
            style={{
              bottom: `${Math.min(0, pinkBottom[0] * width + pinkBottom[1])}px`,
              left: `${Math.min(0, pinkLeft[0] * width + pinkLeft[1])}px`,
            }}
          />
          <img
            src={heroBg1}
            alt=""
            className="absolute"
            style={{
              top: `${Math.min(0, blueTop[0] * width + blueTop[1])}px`,
              left: `${Math.min(0, blueLeft[0] * width + blueLeft[1])}px`,
            }}
          />
          <img
            src={heroBg3}
            alt=""
            className="absolute bottom-0 w-full"
            style={{
              minWidth: `${breakpoint}px`,
              left:
                width < breakpoint ? `calc(50% - ${breakpoint / 2}px)` : "0",
            }}
          />
        </div>
      </div>
    </>
  );
};

HeroGalleryBlok.defaultProps = {
  data: [],
  initialIdx: 0,
};

export default HeroGalleryBlok;
